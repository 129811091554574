<template>
	<section>
		<el-page-header @back="$router.go(-1)" content="捐款資訊" />
		<br>
		<div style="width:100%; max-width:1000px;">
			<el-form label-width="100px" label-position="left">
				<h3 class="theme_text">編號</h3>
				<el-divider />
				<p>{{_support.id}}</p>
				<br>
				<h2 class="theme_text">捐款人資訊</h2>
				<el-divider />
				<el-form-item label="姓名">{{_support.name}}</el-form-item>
				<el-form-item label="電子信箱">{{_support.email}}</el-form-item>
				<el-form-item label="手機號碼">{{_support.cellphone}}</el-form-item>
				<el-form-item label="身分證／統編">{{_support.identity}}</el-form-item>
				<el-form-item label="通訊地址">
					{{_support.zipcode}}
					{{_support.city}}
					{{_support.district}}
					{{_support.address}}
				</el-form-item>
				<h2 class="theme_text">付款資訊</h2>
				<el-divider />
				<el-form-item label="捐款金額">
					<b class="theme_text">{{_support.how_much | currencyFilter}}</b>
				</el-form-item>
				<el-form-item label="付款方式">{{_support.payment}}</el-form-item>
				<el-form-item label="捐款收據">
					<table>
						<tr>
							<th>抬頭</th>
							<td>{{_support.receipt && _support.receipt.title}}</td>
						</tr>
						<tr>
							<th>統編</th>
							<td>{{_support.receipt && _support.receipt.uniform}}</td>
						</tr>
					</table>
				</el-form-item>
				<el-form-item label="指定鄉鎮">
					<template v-if="_support.specified">
						{{_support.specified.city}}
						{{_support.specified.district}}
					</template>
				</el-form-item>
			</el-form>
			<br>
			<br>
			<br>
			<div v-if="!_support.archived" class="center">
				<el-button @click="handleConfirm()" type="danger">已確認款項並封存</el-button>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
	},
	props: ["id"],
	computed: {
		...mapState("support", ["_support"])
	},
	methods: {
		...mapActions("support", [
			"_getData",
			"_archiveData",
		]),
		async handleConfirm() {
			this.$confirm("封存後紀錄可以在「封存列表」裡查詢", "確定要封存？", { type: "warning" })
				.then(async () => {
					this.$message.success(await this._archiveData(this.id));
					this.$router.go(-1);
				})
		},
	},
	created() {
		this._getData(this.id);
	}
}
</script>

<style lang="scss" scoped>
.el-page-header {
	color: $theme;
}
th,
td {
	border: 1px solid $theme-light;
	vertical-align: middle;
	padding: 0 1em;
}
.el-form-item {
	margin: 0 !important;
}
.el-divider {
	height: 2px;
	background: $theme-light;
	margin-top: 0;
}
</style>